import React from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Row, Col } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import Forms from "components/Forms";

import { updateComponentCapacity } from "actions/types";
import { connect } from "react-redux";

const InputField = (props) => <Forms.Input {...props} />;

const Capacity = ({
  component_id,
  capacity_id,
  pristine,
  submitting,
  handleSubmit,
  updateComponentCapacity
}) => {
  const onSubmit = (values) =>
    updateComponentCapacity(
      { component_id, capacity_id },
      values
    );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Field
              label="Nazwa"
              placeholder="nazwa"
              name="item"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Wartość"
              name="value"
              type="text"
              component={InputField}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Jednostka"
              name="text"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Button
          disabled={pristine || submitting}
          bsStyle="success"
          pullRight
          fill
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Zapisz
        </Button>
      </Grid>
    </form>
  );
};

export default connect(null, (dispatch) => ({
  updateComponentCapacity: (ids, values) =>
    dispatch(updateComponentCapacity(ids, values)),
}))(
  reduxForm({
    enableReinitialize: true,
  })(Capacity)
);
