import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Image, Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "components/Loader";
import Forms from "components/Forms";

import {
  getProduct,
  updateProduct,
  resetProduct,
} from "actions/products";

const InputField = (props) => <Forms.Input {...props} />;

const ProductProduct = ({
  product,
  pristine,
  submitting,
  handleSubmit,
  getProduct,
  updateProduct,
  resetProduct,
  match,
}) => {
  useEffect(() => {
    getProduct(match?.params?.id);
    return () => resetProduct();
  }, []);
  const onSubmit = ({ name, image_url, url }) =>
    updateProduct(match?.params?.id, {
      name,
      image_url,
      url,
    });
  if (
    product?.status === "invalid" ||
    product.status === "loading"
  ) {
    return <Loader />;
  }
  if (product?.status === "failure") {
    return <p>Błąd podczass pobierania</p>;
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={4}>
            <Card
              title="Edycja produktu"
              content={
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid fluid>
                    <Row>
                      <Col xs={12}>
                        <Field
                          label="Nazwa źródłowa"
                          name="source_name"
                          type="text"
                          disabled
                          component={InputField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Field
                          label="Nazwa"
                          placeholder="nazwa"
                          name="name"
                          type="text"
                          component={InputField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Field
                          label="Url zdjęcia"
                          placeholder="Url zdjęcia"
                          name="image_url"
                          type="text"
                          component={InputField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Field
                          label="Url produktu"
                          placeholder="Url produktu"
                          name="url"
                          type="text"
                          component={InputField}
                        />
                      </Col>
                    </Row>

                    <Button
                      disabled={pristine || submitting}
                      bsStyle="success"
                      pullRight
                      fill
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Zapisz
                    </Button>
                  </Grid>
                </form>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title="Podgląd zdjęcia"
              content={
                product?.data?.image_url ? (
                  <Image
                    src={product.data?.image_url}
                    thumbnail
                  />
                ) : (
                  <p>Brak zdjęcia</p>
                )
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title="Podgląd zdjęcia źródłowego"
              content={
                <Image
                  src={product?.data?.source_image_url}
                  thumbnail
                />
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProduct: (id) => dispatch(getProduct(id)),
  resetProduct: () => dispatch(resetProduct()),
  updateProduct: (id, data) =>
    dispatch(updateProduct(id, data)),
});

export default connect(
  ({ product }) => ({
    product,
    initialValues: {
      name: product?.data?.name,
      image_url: product?.data?.image_url,
      url: product?.data?.url,
      source_name: product?.data?.source_name,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-product-form",
    enableReinitialize: true,
  })(withRouter(ProductProduct))
);
