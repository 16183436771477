import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "components/Loader";
import Forms from "components/Forms";

import { getUser, updateUser } from "actions/users";

const InputField = props => <Forms.Input {...props} />;

const EditUser = ({ user, pristine, submiting, handleSubmit, getUser, updateUser, match }) => {
  useEffect(() => {
    getUser(match?.params?.id);
  }, []);
  const onSubmit = values => updateUser(match?.params?.id, values);

  if (user?.status === "invalid" || user.status === "loading") {
    return <Loader />;
  }
  if (user?.status === "failure") {
    return <p>Błąd podczass pobierania</p>;
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={6}>
            <Card
              title="Edycja użytkownika"
              content={
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Grid fluid>
                    <Row>
                      <Col xs={6}>
                        <Field label="Imię" placeholder="Imię" name="first_name" type="text" component={InputField} />
                      </Col>
                      <Col xs={6}>
                        <Field
                          label="Nazwisko"
                          placeholder="Nazwisko"
                          name="last_name"
                          type="text"
                          component={InputField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Field label="Login" placeholder="Imię" name="username" type="text" component={InputField} />
                      </Col>
                      <Col xs={6}>
                        <Field
                          label="Hasło"
                          placeholder="Hasło"
                          name="password"
                          type="password"
                          component={InputField}
                        />
                      </Col>
                    </Row>
                    <Button
                      disabled={pristine || submiting}
                      bsStyle="success"
                      pullRight
                      fill
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Zapisz
                    </Button>
                  </Grid>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(getUser(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
});

export default connect(
  ({ user }) => ({
    user,
    initialValues: { first_name: user?.data?.first_name, last_name: user?.data?.last_name, username: user?.data?.username },
  }),
  mapDispatchToProps
)(reduxForm({ form: "edit-user-form", enableReinitialize: true })(withRouter(EditUser)));
