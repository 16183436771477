import React from "react";

const Input = props => {
  return (
    <div className="form-group">
      <label className="control-label">{props.label}</label>
      <input disabled={props.disabled} placeholder={props.placeholder} type={props.type} className="form-control" {...props.input} />
    </div>
  );
};

export default Input;
