import { combineReducers } from "redux";
import { reducer as toastr } from "react-redux-toastr";
import { reducer as form } from "redux-form";

import users from "./users";
import user from "./user";
import makes from "./makes";
import models from "./models";
import _type from "./type";
import types from "./types";
import products from "./products";
import product from "./product";

export default combineReducers({
  form,
  toastr,
  users,
  user,
  makes,
  models,
  types,
  type: _type,
  products,
  product,
});
