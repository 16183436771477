import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getMakes = ({ page = 1, per_page = 15, query = null, loading = true }) => async dispatch => {
  loading && dispatch({ type: "MAKES_LOADING" });
  await client
    .get(`/makes?page=${page}&limit=${per_page}${query ? `&name=${query}` : ""}`)
    .then(({ data }) => {
      dispatch({
        type: "MAKES_SUCCESS",
        data,
      });
    })
    .catch(err => {
      dispatch({ type: "MAKES_FAILURE" });
      toastr.error("Błąd", err?.response?.data?.message || "Wystąpił błąd podzczas pobierania");
    });
};
