import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    return (
      <div id="sidebar" className="sidebar">
        <div className="sidebar-wrapper">
          <ul className="nav">
            <li>
              <NavLink to={"/users"} className="nav-link" activeClassName="active">
                <i className="pe-7s-user" />
                <p>Użytkownicy</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/makes"} className="nav-link" activeClassName="active">
                <i className="pe-7s-car" />
                <p>Marki</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/models"} className="nav-link" activeClassName="active">
                <i className="pe-7s-car" />
                <p>Modele</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/types"} className="nav-link" activeClassName="active">
                <i className="pe-7s-car" />
                <p>Typy silników</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/products"} className="nav-link" activeClassName="active">
                <i className="pe-7s-car" />
                <p>Produkty</p>
              </NavLink>
            </li>
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
