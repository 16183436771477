import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Loader from "components/Loader";
import { getType } from "actions/types";
import Basic from "./components/Basic";
import Components from "./components/Components";

const EditType = ({ type, getType, match }) => {
  useEffect(() => {
    getType(match?.params?.id);
  }, []);

  if (
    type?.status === "invalid" ||
    type.status === "loading"
  ) {
    return <Loader />;
  }
  if (type?.status === "failure") {
    return <p>Błąd podczas pobierania</p>;
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={6}>
            <Card
              title={type?.data?.name}
              content={
                <>
                  <Col md={12}>
                    <p>
                      Marka:{" "}
                      <strong>
                        {type?.data?.model?.make?.name}
                      </strong>
                    </p>
                    <p>
                      Model:{" "}
                      <strong>
                        {type?.data?.model?.name}
                      </strong>
                    </p>
                  </Col>
                  <Basic />
                </>
              }
            />
          </Col>
          <Col md={6}>
            <Components />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getType: (id) => dispatch(getType(id)),
});

export default connect(
  ({ type }) => ({
    type,
  }),
  mapDispatchToProps
)(withRouter(EditType));
