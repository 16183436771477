import React, { useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import Card from "components/Card/Card.jsx";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import Button from "components/CustomButton/CustomButton.jsx";

import { getProducts } from "actions/products";

const ProductsList = ({
  products,
  getProducts,
  location,
  history,
}) => {
  useEffect(() => {
    const query = queryString.parse(location?.search);

    getProducts(query);
  }, []);
  if (
    products?.status === "invalid" ||
    products.status === "loading"
  ) {
    return <Loader />;
  }
  if (products?.status === "failure") {
    return <p>Błąd podczas pobierania</p>;
  }
  const { data, meta } = products.data;
  const { query } = queryString.parse(location?.search);
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={`Produkty (${
                meta?.pagingCounter
              } - ${meta?.limit * meta?.page} / ${
                meta?.totalDocs
              })`}
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <Grid fluid>
                    <Row>
                      <Col md={2}>
                        <div
                          className="form-group"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            placeholder="Szukaj..."
                            defaultValue={query}
                            type="text"
                            className="form-control"
                            onChange={({
                              target: { value },
                            }) => {
                              getProducts({
                                query: value,
                                loading: false,
                              });
                              history.push(
                                `?query=${value}`
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nazwa</th>
                        <th>Nazwa źródłowa</th>
                        <th>Akcje</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 &&
                        data.map(
                          ({ _id, name, source_name }) => (
                            <tr key={_id}>
                              <td>{_id}</td>
                              <td>{name}</td>
                              <td>{source_name}</td>
                              <td>
                                <Button
                                  // style={{ margin: 5, padding: "5px 10px" }}
                                  bsStyle="info"
                                  fill
                                  type="button"
                                  onClick={() =>
                                    history.push(
                                      `/products/${_id}`
                                    )
                                  }
                                >
                                  <i className="pe-7s-note"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </>
              }
            />
            <Pagination
              {...meta}
              action={(page) => getProducts({ page })}
              query={query}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default connect(
  ({ products }) => ({ products }),
  (dispatch) => ({
    getProducts: (data) => dispatch(getProducts(data)),
  })
)(withRouter(ProductsList));
