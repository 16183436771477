import React from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Forms from "components/Forms";
import { handleLogin } from "actions/users";

const InputField = props => <Forms.Input {...props} />;

const Login = ({ history, pristine, submiting, handleSubmit }) => {
  const onSubmit = values => {
    handleLogin(values).then(() => (window.location.pathname = "/users"));
  };
  return (
    <div style={{ display: "flex", alignItems: "center", minHeight: "100vh", width: "100%" }}>
      <Row style={{ width: "100%" }}>
        <Col xs={4} xsOffset={4}>
          <Card
            title="Autoryzacja"
            content={
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Field label="Login" placeholder="Login" name="username" type="text" component={InputField} />
                <Field label="Hasło" placeholder="Hasło" name="password" type="password" component={InputField} />
                <Button
                  disabled={pristine || submiting}
                  bsStyle="info"
                  pullRight
                  fill
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Zaloguj
                </Button>
                <div className="clearfix" />
              </form>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default reduxForm({ form: "login-form", enableReinitialize: true })(withRouter(Login));
