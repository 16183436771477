import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Select from "react-select";
import { Grid, Row, Col } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import { updateComponentRecommendation } from "actions/types";

const Recommendation = ({
  change,
  product_options,
  component_id,
  recommendation_id,
  pristine,
  submitting,
  handleSubmit,
  updateComponentRecommendation,
  initialValues,
}) => {
  const onSubmit = (values) =>
    updateComponentRecommendation(
      { component_id, recommendation_id },
      values
    );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid style={{ marginBottom: 20 }}>
        <Row>
          <Col xs={12}>
            <Select
              cacheOptions
              defaultValue={
                product_options?.length > 0
                  ? product_options.find(
                      ({ value }) =>
                        value === initialValues.product_id
                    )
                  : []
              }
              options={product_options}
              defaultOptions
              onChange={(item) => change('product_id',item.value) }
            />
          </Col>
        </Row>
        <Button
          disabled={pristine || submitting}
          bsStyle="success"
          pullRight
          fill
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Zapisz
        </Button>
      </Grid>
    </form>
  );
};

export default connect(
  ({ type: { product_options } }) => ({ product_options }),
  (dispatch) => ({
    updateComponentRecommendation: (ids, values) =>
      dispatch(updateComponentRecommendation(ids, values)),
  })
)(
  reduxForm({
    enableReinitialize: true,
  })(Recommendation)
);
