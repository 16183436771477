import React, { useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import Card from "components/Card/Card.jsx";
import Loader from "components/Loader";
import Pagination from "components/Pagination";

import { getModels } from "actions/models";
import moment from "moment";

const ModelsList = ({
  models,
  getModels,
  location,
  history,
}) => {
  useEffect(() => {
    const query = queryString.parse(location?.search);

    getModels(query);
  }, []);
  if (
    models?.status === "invalid" ||
    models.status === "loading"
  ) {
    return <Loader />;
  }
  if (models?.status === "failure") {
    return <p>Błąd podczas pobierania</p>;
  }
  const { data, meta } = models.data;
  const { query } = queryString.parse(location?.search);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={`Modele (${
                meta?.pagingCounter
              } - ${meta?.limit * meta?.page} / ${
                meta?.totalDocs
              })`}
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <Grid fluid>
                    <Row>
                      <Col md={2}>
                        <div
                          className="form-group"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            placeholder="Szukaj..."
                            defaultValue={query}
                            type="text"
                            className="form-control"
                            onChange={({
                              target: { value },
                            }) => {
                              getModels({
                                query: value,
                                loading: false,
                              });
                              history.push(
                                `?query=${value}`
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Ilość typów</th>
                        <th>Data utworzenia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 &&
                        data.map(
                          ({
                            _id,
                            make,
                            name,
                            types,
                            created_at,
                          }) => (
                            <tr key={_id}>
                              <td>{_id}</td>
                              <td>{make?.name}</td>
                              <td>{name}</td>
                              <td>{types?.length}</td>
                              <td>
                                {moment(created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </>
              }
            />
            <Pagination
              {...meta}
              action={(page) => getModels({ page })}
              query={query}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getModels: (data) => dispatch(getModels(data)),
});

export default connect(
  ({ models }) => ({ models }),
  mapDispatchToProps
)(withRouter(ModelsList));
