import React, { Component } from "react";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import Router from "../Router.js";

class Admin extends Component {
  render() {
    return (
      <div className="wrapper">
        <Sidebar {...this.props} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} />
          <Router />
        </div>
      </div>
    );
  }
}

export default Admin;
