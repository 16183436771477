import React from "react";
import Component from "./components/Component";
import { connect } from "react-redux";

const Components = ({ components }) => {
  return components.map((item) => (
    <Component key={item._id} data={item} />
  ));
};

export default connect(
  ({
    type: {
      data: { components },
    },
  }) => ({ components })
)(Components);
