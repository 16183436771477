import React from "react";
import { render } from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import ReduxToastr from "react-redux-toastr";
import { Provider } from "react-redux";
import store, { history } from "./store";
import { CookiesProvider } from "react-cookie";
import { getCookie } from "utils/cookies";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import AdminLayout from "layouts/Admin.jsx";
import Login from "views/Login.jsx";

render(
  <Provider store={store}>
    <CookiesProvider>
         <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <ConnectedRouter history={history}>{getCookie("token") ? <AdminLayout /> : <Login />}</ConnectedRouter>
    </CookiesProvider>
  </Provider>,
  document.querySelector("#root")
);
