const initialState = {
  status: "invalid",
  data: null,
  product_options: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "TYPE_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "TYPE_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "TYPE_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "TYPE_UPDATE_COMPONENT": {
      const components = [...state.data.components];
      const component_index = components.indexOf(
        components.find(
          ({ _id }) => _id === action.payload._id
        )
      );
      if (component_index > -1) {
        components[component_index] = action.payload;
      }

      return {
        ...state,
        data: { ...state.data, components },
      };
    }
    default:
      return state;
  }
};
