import React, { useEffect } from "react";
import { Grid, Row, Col, Table, ButtonToolbar } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "components/Loader";
import { getUsers } from "actions/users";

const UsersList = ({ users, getUsers, history }) => {
  useEffect(() => {
    getUsers({ page: 1, per_page: 10 });
  }, []);
  if (users?.status === "invalid" || users.status === "loading") {
    return <Loader />;
  }
  if (users?.status === "failure") {
    return <p>Błąd podczass pobierania</p>;
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Użytkownicy"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Imię</th>
                      <th>Nazwisko</th>
                      <th>Login</th>
                      <th>Data utworzenia</th>
                      <th>Akcje</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.data?.length > 0 &&
                      users.data.map(({ id, first_name, last_name, username, createdDate }) => (
                        <tr key={id}>
                          <td>{id}</td>
                          <td>{first_name}</td>
                          <td>{last_name}</td>
                          <td>{username}</td>
                          <td>{createdDate}</td>
                          <td>
                            <ButtonToolbar>
                              <Button bsStyle="info" fill type="button" onClick={() => history.push(`/users/${id}`)}>
                                <i className="pe-7s-note"></i>
                              </Button>
                              {/* <Button bsStyle="danger" fill type="button">
                                <i className="pe-7s-trash"></i>
                              </Button> */}
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getUsers: data => dispatch(getUsers(data)),
});

export default connect(({ users }) => ({ users }), mapDispatchToProps)(withRouter(UsersList));
