import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "views/Dashboard.jsx";
import Users from "views/users";
import Models from "views/models";
import Makes from "views/makes";
import Types from "views/types";
import Oils from "views/products";

const Router = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path="/dashboard"
        component={Dashboard}
      />
      <Route
        exact={true}
        path="/users"
        component={Users.List}
      />
      <Route
        exact={true}
        path="/users/:id"
        component={Users.Edit}
      />
      <Route
        exact={true}
        path="/makes"
        component={Makes.List}
      />
      <Route
        exact={true}
        path="/models"
        component={Models.List}
      />
      <Route
        exact={true}
        path="/types"
        component={Types.List}
      />
      <Route
        exact={true}
        path="/types/:id"
        component={Types.Edit}
      />
      <Route
        exact={true}
        path="/products"
        component={Oils.List}
      />
      <Route
        exact={true}
        path="/products/:id"
        component={Oils.Edit}
      />
    </Switch>
  );
};

export default Router;
