import axios from "axios";
import { getCookie } from "./cookies";

export const url = "https://api.pmo-oils.com";

axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie("token")}`;

export const client = axios.create({ baseURL: url });

export const handleErrors = data => {
  try {
    const errors = {};

    data.map(({ detail, source: { pointer } }) => (errors[pointer.split("/")[pointer.split("/").length - 1]] = detail));
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};
