import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getProducts = ({
  page = 1,
  per_page = 15,
  query = null,
  loading = true,
}) => async (dispatch) => {
  loading && dispatch({ type: "PRODUCTS_LOADING" });
  await client
    .get(
      `/products?page=${page}&limit=${per_page}${
        query ? `&name=${query}` : ""
      }`
    )
    .then(({ data }) => {
      dispatch({
        type: "PRODUCTS_SUCCESS",
        data,
      });
    })
    .catch((err) => {
      dispatch({ type: "PRODUCTS_FAILURE" });
      toastr.error(
        "Błąd",
        err?.response?.data?.message ||
          "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const getProduct = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    await client
      .get(`/products/${id}`)
      .then(({ data }) => {
        dispatch({
          type: "PRODUCT_SUCCESS",
          data,
        });
        resolve();
      })
      .catch((_) => {
        dispatch({
          type: "PRODUCT_FAILURE",
        });
        reject();
      });
  });

export const updateProduct = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/products/${id}`, data)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Olej został zaktualizowany"
        );
        dispatch({
          type: "PRODUCT_SUCCESS",
          data,
        });
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podczas aktualizacji oleju"
        );
        reject(err);
      });
  });

export const resetProduct = () => (dispatch) =>
  dispatch({ type: "PRODUCT_INVALID" });
