import React from "react";
import { Grid, Button } from "react-bootstrap";
import { handleLogout } from "actions/users";
const AdminNavbarLinks = () => {
  return (
    <Grid fluid>
      <Button style={{ float: "right" }} onClick={handleLogout}>
        Wyloguj
      </Button>
    </Grid>
  );
};

export default AdminNavbarLinks;
