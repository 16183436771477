import React from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Row, Col } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import Forms from "components/Forms";

import { getType, updateType } from "actions/types";

const InputField = (props) => <Forms.Input {...props} />;

const Basic = ({
  pristine,
  submiting,
  handleSubmit,
  updateType,
  match,
}) => {
  const onSubmit = (data) =>
    updateType(match?.params?.id, data);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Field
              label="Nazwa"
              placeholder="nazwa"
              name="name"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Moc hp"
              name="power_hp"
              type="text"
              component={InputField}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Moc kw"
              name="power_kw"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Moc RPM"
              name="power_rpm"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Paliwo"
              name="fuel"
              type="text"
              component={InputField}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Napęd"
              name="drive_type"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Pojemność cylindrów"
              name="cylinder_capacity"
              type="text"
              component={InputField}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Ilość cylindrów"
              name="cylinder_count"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Budowa silika"
              name="engine_build"
              type="text"
              component={InputField}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Ilość zaworów"
              name="valve_count"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              label="Rozpoczęcie produkcji"
              name="year_start"
              type="text"
              component={InputField}
            />
          </Col>
          <Col xs={6}>
            <Field
              label="Zakończenie produkcji"
              name="year_end"
              type="text"
              component={InputField}
            />
          </Col>
        </Row>
        <Button
          disabled={pristine || submiting}
          bsStyle="success"
          pullRight
          fill
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Zapisz
        </Button>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getType: (id) => dispatch(getType(id)),
  updateType: (id, data) => dispatch(updateType(id, data)),
});

export default connect(
  ({ type }) => ({
    type,
    initialValues: {
      name: type?.data?.name,
      power_hp: type?.data?.power_hp,
      power_kw: type?.data?.power_kw,
      power_rpm: type?.data?.power_rpm,
      fuel: type?.data?.fuel,
      drive_type: type?.data?.drive_type,
      cylinder_capacity: type?.data?.cylinder_capacity,
      cylinder_count: type?.data?.cylinder_count,
      engine_build: type?.data?.engine_build,
      valve_count: type?.data?.valve_count,
      year_start: type?.data?.year_start,
      year_end: type?.data?.year_end,
    },
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-type-basic-form",
    enableReinitialize: true,
  })(withRouter(Basic))
);
