import React, { useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import queryString from "query-string";
import Card from "components/Card/Card.jsx";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { getMakes } from "actions/makes";

const MakesList = ({
  makes,
  getMakes,
  location,
  history,
}) => {
  useEffect(() => {
    const query = queryString.parse(location?.search);
    getMakes(query);
  }, []);
  if (
    makes?.status === "invalid" ||
    makes.status === "loading"
  ) {
    return <Loader />;
  }
  if (makes?.status === "failure") {
    return <p>Błąd podczas pobierania</p>;
  }
  const { data, meta } = makes.data;
  const { query } = queryString.parse(location?.search);
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={`Marki ${meta &&
                `(${meta?.pagingCounter} - ${meta?.limit *
                  meta?.page} / ${meta?.totalDocs})`}`}
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <Grid fluid>
                    <Row>
                      <Col md={2}>
                        <div
                          className="form-group"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            placeholder="Szukaj..."
                            defaultValue={query}
                            type="text"
                            className="form-control"
                            onChange={({
                              target: { value },
                            }) => {
                              getMakes({
                                query: value,
                                loading: false,
                              });
                              history.push(
                                `?query=${value}`
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Marka</th>
                        <th>Ilość modeli</th>
                        <th>Data utworzenia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 &&
                        makes.data.data.map(
                          ({
                            _id,
                            models,
                            name,
                            created_at,
                          }) => (
                            <tr key={_id}>
                              <td>{_id}</td>
                              <td>{name}</td>
                              <td>{models?.length}</td>
                              <td>{moment(created_at).format('YYYY-MM-DD HH:mm')}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </>
              }
            />
            <Pagination
              {...meta}
              action={(page) => getMakes({ page, query })}
              query={query}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMakes: (data) => dispatch(getMakes(data)),
});

export default connect(
  ({ makes }) => ({ makes }),
  mapDispatchToProps
)(withRouter(MakesList));
