import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getTypes = ({
  page = 1,
  per_page = 15,
  query = null,
  loading = true,
}) => async (dispatch) => {
  loading && dispatch({ type: "TYPES_LOADING" });
  await client
    .get(
      `/types?page=${page}&limit=${per_page}${
        query ? `&name=${query}` : ""
      }`
    )
    .then(({ data }) => {
      dispatch({
        type: "TYPES_SUCCESS",
        data,
      });
    })
    .catch((err) => {
      dispatch({ type: "TYPES_FAILURE" });
      toastr.error(
        "Błąd",
        err?.response?.data?.message ||
          "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const getType = (id) => async (dispatch) => {
  try {
    const { data } = await client.get(`/types/${id}`);
    const { data: products } = await client.get(
      `/products/list`
    );

    const product_options = products.map(
      ({ _id, name, source_name }) => ({
        label: `${name || "brak"} - (${source_name})`,
        value: _id,
      })
    );
    dispatch({
      type: "TYPE_SUCCESS",
      data,
      product_options,
    });
  } catch (error) {
    dispatch({
      type: "TYPE_FAILURE",
    });
  }
};

export const updateType = (id, data) => async (
  dispatch
) => {
  try {
    await client.put(`/types/${id}`, data);
    toastr.success("Sukces", "Zaktualizowno pomyślnie");
    dispatch(getType(id));
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizacji"
    );
  }
};

export const updateComponentCapacity = (
  { component_id, capacity_id },
  values
) => async (dispatch) => {
  try {
    const { data } = await client.put(
      `/components/${component_id}/capacities/${capacity_id}`,
      values
    );
    toastr.success(
      "Błąd",
      "Pojemność została zaktualizowana"
    );
    dispatch({
      type: "TYPE_UPDATE_COMPONENT",
      payload: data,
    });
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizacji pojemności"
    );
  }
};

export const updateComponentRecommendation = (
  { component_id, recommendation_id },
  values
) => async (dispatch) => {
  try {
    const { data } = await client.put(
      `/components/${component_id}/recommendations/${recommendation_id}`,
      values
    );
    toastr.success(
      "Błąd",
      "Komponent został zaktualizowany"
    );
    dispatch({
      type: "TYPE_UPDATE_COMPONENT",
      payload: data,
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd.");
  }
};
