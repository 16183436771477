import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";
import { setCookie, removeCookie } from "utils/cookies";

export const handleLogin = data =>
  new Promise(
    async (resolve, reject) =>
      await client
        .post(`/users/auth`, data)
        .then(async ({ data: { token } }) => {
          await setCookie("token", token);
          resolve();
        })
        .catch(err => {
          toastr.error("Błąd", err.response.data.message);
          reject();
        })
  );

export const handleLogout = async data => {
  await removeCookie("token");
  window.location.pathname = "/";
};

export const getUsers = ({ page = 1, per_page = 10, query = null, loading = true }) => async dispatch => {
  loading && dispatch({ type: "USERS_LOADING" });
  await client
    .get(`/users`)
    .then(({ data }) => {
      dispatch({
        type: "USERS_SUCCESS",
        data,
      });
    })
    .catch(err => {
      dispatch({ type: "USERS_FAILURE" });
      toastr.error("Błąd", err?.response?.data?.message || "Wystąpił błąd podzczas pobierania");
    });
};

export const getUser = id => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .get(`/users/${id}`)
      .then(({ data }) => {
        dispatch({
          type: "USER_SUCCESS",
          data,
        });
        resolve();
      })
      .catch(err => {
        reject();
      });
  });


export const createUser = data => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/users`, data)
      .then(() => resolve())
      .catch(err => {
        reject(handleErrors(err.response.data.errors));
      });
  });

export const deleteUser = id => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .delete(`/users/${id}`)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(handleErrors(err.response.data.errors));
      });
  });

export const updateUser = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    await client
      .put(`/users/${id}`, data)
      .then(({ data }) => {
        toastr.success("Sukces", "Użytkownik został zaktualizowany");
        dispatch({
          type: "USER_SUCCESS",
          data,
        });
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd podczas aktualizacji użytkownika");
        reject(handleErrors(err));
      });
  });
