import React from "react";
import { withRouter } from "react-router-dom";
import { Pagination } from "react-bootstrap";

const AdminPagination = ({
  history,
  query = null,
  hasNextPage,
  hasPrevPage,
  page,
  totalPages,
  nextPage,
  prevPage,
  action,
}) => {
  const handleClick = data => {
    action(data);
    history.push(`?page=${data}${query ? `&query=${query}` : ""}`);
  };
  if (page > totalPages) {
    return null;
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Pagination>
        {hasPrevPage && <Pagination.Prev onClick={() => handleClick(prevPage)} />}
        {page - 3 > 0 && <Pagination.Item onClick={() => handleClick(page - 3)}>{page - 3}</Pagination.Item>}
        {page - 2 > 0 && <Pagination.Item onClick={() => handleClick(page - 2)}>{page - 2}</Pagination.Item>}
        {page - 1 > 0 && <Pagination.Item onClick={() => handleClick(page - 1)}>{page - 1}</Pagination.Item>}
        <Pagination.Item active>{page}</Pagination.Item>
        {page + 1 <= totalPages && <Pagination.Item onClick={() => handleClick(page + 1)}>{page + 1}</Pagination.Item>}
        {page + 2 <= totalPages && <Pagination.Item onClick={() => handleClick(page + 3)}>{page + 2}</Pagination.Item>}
        {page + 3 <= totalPages && <Pagination.Item onClick={() => handleClick(page + 3)}>{page + 3}</Pagination.Item>}

        {hasNextPage && <Pagination.Next onClick={() => handleClick(nextPage)} />}
      </Pagination>
    </div>
  );
};

export default withRouter(AdminPagination);
