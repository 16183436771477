import React from "react";
import Spinner from "react-loader-spinner";

const Loader = () => {
  return (
    <div style={styles}>
      <Spinner type="Oval" color="#00BFFF" height={100} width={100} />
    </div>
  );
};

export default Loader;

const styles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "calc(100vh - 71px)",
};
