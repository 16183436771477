import React from "react";

import Card from "components/Card/Card";
import Capacity from "./components/Capacity";
import Recommendation from "./components/Recommendation";

const Component = ({ data }) => {
  return (
    <Card
      title={data?.name}
      content={
        <>
          <h5>Pojemność</h5>
          {data?.capacities.map((el) => (
            <Capacity
              key={el._id}
              form={`capacity_${el._id}`}
              component_id={data._id}
              capacity_id={el._id}
              initialValues={{
                item: el.item,
                text: el.text,
                value: el.value,
              }}
            />
          ))}
          <h5>Rekomendacje</h5>
          {data?.recommendations.map((el) => (
            <Recommendation
              key={el._id}
              form={`recommendation_${el._id}`}
              component_id={data._id}
              recommendation_id={el._id}
              initialValues={{
                product_id: el.product._id,
              }}
            />
          ))}
        </>
      }
    />
  );
};

export default Component;
